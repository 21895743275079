import config from './../config';
import { takeEvery, call, put } from 'redux-saga/effects';
import { GET_BUNDLES, GET_BUNDLES_RESULT, GET_BUNDLES_ERROR } from './actions';
import { GET_STREAM, GET_STREAM_RESULT, GET_STREAM_ERROR } from './actions';
import { GET_PROVIDER_PARAMS, GET_PROVIDER_PARAMS_RESULT, GET_PROVIDER_PARAMS_ERROR } from './actions';
console.log(JSON.stringify(config));
const BUNDLES_URL = config.baseApiUrl + "/" + config.apiStage + "/bundles";
const STREAM_URL = config.baseApiUrl + "/" + config.apiStage + "/stream";
const PROVIDER_PARAMS_URL = config.baseApiUrl + "/" + config.apiStage + "/provider";

const getProviderParams = (provider) => {
  const url = provider ? PROVIDER_PARAMS_URL + "?key=" + provider : PROVIDER_PARAMS_URL;
  var request = new Request(url, {
    headers: new Headers({
      'Content-Type': 'application/json',
    }),
    method: "GET"
  });

  return fetch(request);
};

const getBundles = (token) => {
  var request = new Request(BUNDLES_URL, {
    headers: new Headers({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token.id_token
    }),
    method: "GET"
  });

  return fetch(request)
};

const getStreams = (token, applicationName, stackName) => {
  var request = new Request(STREAM_URL, {
    headers: new Headers({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token.id_token
    }),
    method: "POST",
    body: JSON.stringify({ "stackName": stackName, "applicationName": applicationName })
  });

  return fetch(request);
};

function* getStreamApi(action) {
  let token = action.token;
  let stackName = action.stackName;
  let applicationName = action.applicationName;

  try {
    const response = yield call(getStreams, token, applicationName, stackName);
    console.log("get stream response", response)
    const result = yield response.json();
    console.log("get stream result", result)

    if (result.Error) {
      yield put({ type: GET_STREAM_ERROR, error: result.Error });
    } else {
      yield put({ type: GET_STREAM_RESULT, streamUrl: result.streamURL, reference: result.reference });
    }
  } catch (e) {
    console.log("get stream exception", e.message)
    yield put({ type: GET_STREAM_ERROR, error: e.message });
  }
};

function* getBundlesApi(action) {
  let token = action.token;

  try {
    const response = yield call(getBundles, token);
    console.log("get bundles response", response)
    const result = yield response.json();
    console.log("get bundles result", result)

    if (result.Error) {
      yield put({ type: GET_BUNDLES_ERROR, error: result.Error });
    } else {
      yield put({ type: GET_BUNDLES_RESULT, bundles: result });
    }
  } catch (e) {
    yield put({ type: GET_BUNDLES_ERROR, error: e.message });
  }
};

function* getProviderParamsApi(action) {
  let provider = action.provider;

  try {
    const response = yield call(getProviderParams, provider);
    console.log("get provider params response", response)
    const result = yield response.json();
    console.log("get provider params result", result)

    if (result.Error) {
      yield put({ type: GET_PROVIDER_PARAMS_ERROR, error: result.Error });
    } else {
      yield put({ type: GET_PROVIDER_PARAMS_RESULT, provider: result.providerName, clientId: result.clientId, redirectUri: result.redirectUri, scopes: result.scopes, logoutUri: result.logoutUri });
    }
  } catch (e) {
    console.log("get bundles exception", e.message)
    yield put({ type: GET_PROVIDER_PARAMS_ERROR, error: e.message });
  }
};

export default function* rootSaga() {
  yield takeEvery(GET_BUNDLES, getBundlesApi);
  yield takeEvery(GET_STREAM, getStreamApi);
  yield takeEvery(GET_PROVIDER_PARAMS, getProviderParamsApi);
};
