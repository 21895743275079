import React from 'react';

const SignIn = React.lazy(() => import('./App/layout/SignIn'));
const SignOut = React.lazy(() => import('./App/layout/SignOut'));

const route = [
    { path: '/id/:name', name: 'Sign In', component: SignIn, exact: true },
    { path: '/signedout', name: 'Signed Out', component: SignOut, exact: true },
];

export default route;
