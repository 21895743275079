import * as actionTypes from './actions';
import config from './../config';

// Load logos
import logoBlank from '../assets/images/logo-blank.png'
import logoCrayon from '../assets/images/crayon-cor-logo.png'
import logoXYZUniversity from '../assets/images/crayon-cor-logo.png'

const authUrl = config.baseAuthUrl + "/oauth2/authorize?identity_provider=###PROVIDER_NAME###&redirect_uri=###REDIRECT_URI###&client_id=###CLIENT_ID###&scope=###SCOPES###&response_type=TOKEN";
const signInPath = "/id/";
const signOutUrl = config.baseAuthUrl + "/logout?";
const signOutUri = config.defaultSignOutUri;

const application = {
    name: "",
    displayName: "",
    description: "",
    iconUrl: "",
}

const stack = {
    displayName: "",
    description: "",
    applications: [
        application
    ],
}

const bundle = [
    stack
]

const initialState = {
    isOpen: [], //for active default menu
    isTrigger: [], //for active default menu, set blank for horizontal
    ...config,
    isFullScreen: false, // static can't change
    loginUrl: "",
    redirectUrl: "",
    oauthState: "",
    token: {
        access_token: "",
        expires_in: 0,
        id_token: "",
        token_type: "",
    },
    error: "",
    bundles: bundle,
    streamUrl: "",
    showAlert: false,
    signInPath: signInPath,
    signOutUrl: signOutUrl,
    signOutUri: signOutUri,
    institution: "",
    redirectLoginCount: 0,
    loggedOut: false,
    clientId: "",
    redirectUri: "",
    scopes: "",
    logo: logoBlank,
};

const reducer = (state = initialState, action) => {
    let trigger = [];
    let open = [];

    switch (action.type) {
        case actionTypes.COLLAPSE_MENU:
            return {
                ...state,
                collapseMenu: !state.collapseMenu
            };
        case actionTypes.COLLAPSE_TOGGLE:
            if (action.menu.type === 'sub') {
                open = state.isOpen;
                trigger = state.isTrigger;

                const triggerIndex = trigger.indexOf(action.menu.id);
                if (triggerIndex > -1) {
                    open = open.filter(item => item !== action.menu.id);
                    trigger = trigger.filter(item => item !== action.menu.id);
                }

                if (triggerIndex === -1) {
                    open = [...open, action.menu.id];
                    trigger = [...trigger, action.menu.id];
                }
            } else {
                open = state.isOpen;
                const triggerIndex = (state.isTrigger).indexOf(action.menu.id);
                trigger = (triggerIndex === -1) ? [action.menu.id] : [];
                open = (triggerIndex === -1) ? [action.menu.id] : [];
            }

            return {
                ...state,
                isOpen: open,
                isTrigger: trigger
            };
        case actionTypes.NAV_CONTENT_LEAVE:
            return {
                ...state,
                isOpen: open,
                isTrigger: trigger,
            };
        case actionTypes.NAV_COLLAPSE_LEAVE:
            if (action.menu.type === 'sub') {
                open = state.isOpen;
                trigger = state.isTrigger;

                const triggerIndex = trigger.indexOf(action.menu.id);
                if (triggerIndex > -1) {
                    open = open.filter(item => item !== action.menu.id);
                    trigger = trigger.filter(item => item !== action.menu.id);
                }
                return {
                    ...state,
                    isOpen: open,
                    isTrigger: trigger,
                };
            }
            return { ...state };
        case actionTypes.FULL_SCREEN:
            return {
                ...state,
                isFullScreen: !state.isFullScreen
            };
        case actionTypes.FULL_SCREEN_EXIT:
            return {
                ...state,
                isFullScreen: false
            };
        case actionTypes.CHANGE_LAYOUT:
            return {
                ...state,
                layout: action.layout
            };
        case actionTypes.CHANGE_PRE_LAYOUT:
            return {
                ...state,
                preLayout: action.preLayout
            };
        case actionTypes.LAYOUT_TYPE:
            return {
                ...state,
                layoutType: action.layoutType,
                navBackColor: (action.layoutType === 'dark' && initialState.navBackColor === 'navbar-default') ? 'navbar-dark' : state.navBackColor,
                navBrandColor: (action.layoutType === 'dark' && initialState.navBrandColor === 'brand-default') ? 'brand-dark' : state.navBrandColor,
                navBackImage: initialState.navBackImage,
                headerBackColor: initialState.headerBackColor
            };
        case actionTypes.NAV_BACK_COLOR:
            return {
                ...state,
                navBackColor: action.navBackColor,
                navBackImage: initialState.navBackImage,
                navBrandColor: 'brand-default',
                layoutType: (state.layoutType === 'menu-light') ? 'menu-dark' : state.layoutType
            };
        case actionTypes.NAV_BACK_IMAGE:
            return {
                ...state,
                layoutType: 'menu-dark',
                navBackImage: action.navBackImage,
                navBrandColor: '',
                navBackColor: ''
            };
        case actionTypes.NAV_BRAND_COLOR:
            return {
                ...state,
                navBrandColor: action.navBrandColor
            };
        case actionTypes.HEADER_BACK_COLOR:
            return {
                ...state,
                headerBackColor: action.headerBackColor
            };
        case actionTypes.NAV_ICON_COLOR:
            return {
                ...state,
                navIconColor: !state.navIconColor
            };
        case actionTypes.RTL_LAYOUT:
            return {
                ...state,
                rtlLayout: !state.rtlLayout
            };
        case actionTypes.NAV_FIXED_LAYOUT:
            return {
                ...state,
                navFixedLayout: !state.navFixedLayout
            };
        case actionTypes.HEADER_FIXED_LAYOUT:
            return {
                ...state,
                headerFixedLayout: !state.headerFixedLayout,
                headerBackColor: (!state.headerFixedLayout && initialState.headerBackColor === 'header-default') ? 'header-blue' : state.headerBackColor,
                navBrandColor: (!state.headerFixedLayout) ? 'brand-default' : initialState.navBrandColor
            };
        case actionTypes.BOX_LAYOUT:
            return {
                ...state,
                boxLayout: !state.boxLayout
            };
        case actionTypes.LAYOUT6_BACKGROUND:
            return {
                ...state,
                layout6Background: action.value.layout6Background,
                layout6BackSize: action.value.layout6BackSize
            };
        case actionTypes.NAV_DROPDOWN_ICON:
            return {
                ...state,
                navDropdownIcon: action.navDropdownIcon,
            };
        case actionTypes.NAV_LIST_ICON:
            return {
                ...state,
                navListIcon: action.navListIcon,
            };
        case actionTypes.NAV_ACTIVE_LIST_COLOR:
            return {
                ...state,
                navActiveListColor: action.navActiveListColor,
            };
        case actionTypes.NAV_LIST_TITLE_COLOR:
            return {
                ...state,
                navListTitleColor: action.navListTitleColor,
            };
        case actionTypes.NAV_LIST_TITLE_HIDE:
            return {
                ...state,
                navListTitleHide: !state.navListTitleHide,
            };
        case actionTypes.CONFIG_BLOCK:
            return {
                ...state,
                configBlock: !state.configBlock,
            };
        case actionTypes.RESET:
            return {
                ...state,
                layout: initialState.layout,
                preLayout: initialState.preLayout,
                collapseMenu: initialState.collapseMenu,
                layoutType: initialState.layoutType,
                navIconColor: initialState.navIconColor,
                headerBackColor: initialState.headerBackColor,
                navBackColor: initialState.navBackColor,
                navBrandColor: initialState.navBrandColor,
                navBackImage: initialState.navBackImage,
                rtlLayout: initialState.rtlLayout,
                navFixedLayout: initialState.navFixedLayout,
                headerFixedLayout: initialState.headerFixedLayout,
                boxLayout: initialState.boxLayout,
                navDropdownIcon: initialState.navDropdownIcon,
                navListIcon: initialState.navListIcon,
                navActiveListColor: initialState.navActiveListColor,
                navListTitleColor: initialState.navListTitleColor,
                navListTitleHide: initialState.navListTitleHide,
                layout6Background: initialState.layout6Background
            };
        case actionTypes.ACTION:
            return {
                ...state,
            };
        case actionTypes.GET_LOGIN_URL: {
            const providerName = encodeURIComponent(action.provider);
            const clientId = encodeURIComponent(action.clientId);
            const redirectUri = encodeURIComponent(action.redirectUri);
            const scopes = encodeURIComponent(action.scopes);

            let url = authUrl;
            url = url.replace("###PROVIDER_NAME###", providerName);
            url = url.replace("###CLIENT_ID###", clientId);
            url = url.replace("###REDIRECT_URI###", redirectUri);
            url = url.replace("###SCOPES###", scopes);

            return {
                ...state,
                redirectUrl: url,
            };
        }
        case actionTypes.SET_TOKEN:
            return {
                ...state,
                token: action.token,
            };
        case actionTypes.GET_BUNDLES:
            return {
                ...state,
            };
        case actionTypes.GET_BUNDLES_RESULT:
            state.bundles = [];
            action.bundles.forEach((bun, ix) => {
                state.bundles.push(bun)
            })
            return {
                ...state,
            };
        case actionTypes.GET_BUNDLES_ERROR:
            return {
                ...state,
                error: action.error
            };
        case actionTypes.GET_STREAM:
            return {
                ...state,
            };
        case actionTypes.GET_STREAM_RESULT:
            return {
                ...state,
                streamUrl: action.streamUrl
            };
        case actionTypes.GET_STREAM_ERROR:
            return {
                ...state,
                error: action.error
            };
        case actionTypes.GET_PROVIDER_PARAMS:
            return {
                ...state,
                institution: action.provider
            };
        case actionTypes.GET_PROVIDER_PARAMS_RESULT: {
            const scopes = action.scopes ? action.scopes.join(" ") : null;
            let logoutUri = signOutUri;
            if (typeof action.signOutUri !== 'undefined' && action.signOutUri) {
                logoutUri = action.logoutUri;
            }
            return {
                ...state,
                provider: action.provider,
                clientId: action.clientId,
                redirectUri: action.redirectUri,
                scopes: scopes,
                signOutUrl: signOutUrl + "client_id=" + encodeURIComponent(action.clientId) + "&logout_uri=" + encodeURIComponent(logoutUri)
            };
        }
        case actionTypes.GET_PROVIDER_PARAMS_ERROR:
            return {
                ...state,
                error: action.error
            };
        case actionTypes.SHOW_ALERT:
            return {
                ...state,
                showAlert: true,
            };
        case actionTypes.HIDE_ALERT:
            return {
                ...state,
                showAlert: false
            };
        case actionTypes.SET_INSTITUTION:
            return {
                ...state,
                institution: action.institution
            };
        case actionTypes.INCREASE_REDIRECT_LOGIN_COUNT:
            return {
                ...state,
                redirectLoginCount: state.redirectLoginCount + 1
            };
        case actionTypes.RESET_REDIRECT_LOGIN_COUNT:
            return {
                ...state,
                redirectLoginCount: 0
            };
        case actionTypes.GET_LOGO:
            console.log("institution", state.institution)
            // Set default logo
            let logo = logoBlank
            switch (state.institution) {
              case "crayon":
                logo = logoCrayon;
                break;
              case "xyz":
                logo = logoXYZUniversity;
                break;
              default:
                logo = logoCrayon;
                break;
            }
            return {
                ...state,
                logo: logo,
            };
        case actionTypes.LOG_OUT:
            return {
                ...state,
                loggedOut: true,
                token: {},
            };
        default:
            return state;
    }
};

export default reducer;
