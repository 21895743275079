export const COLLAPSE_MENU = 'COLLAPSE_MENU';
export const COLLAPSE_TOGGLE = 'COLLAPSE_TOGGLE';
export const FULL_SCREEN = 'FULL_SCREEN';
export const FULL_SCREEN_EXIT = 'FULL_SCREEN_EXIT';
export const CHANGE_LAYOUT = 'CHANGE_LAYOUT';
export const LAYOUT_TYPE = 'LAYOUT_TYPE';
export const RESET = 'RESET';
export const NAV_BACK_COLOR = 'NAV_BACK_COLOR';
export const NAV_BACK_IMAGE = 'NAV_BACK_IMAGE';
export const NAV_BRAND_COLOR = 'NAV_BRAND_COLOR';
export const HEADER_BACK_COLOR = 'HEADER_BACK_COLOR';
export const NAV_ICON_COLOR = 'NAV_ICON_COLOR';
export const LAYOUT6_BACKGROUND = 'LAYOUT6_BACKGROUND';
export const RTL_LAYOUT = 'RTL_LAYOUT';
export const NAV_FIXED_LAYOUT = 'NAV_FIXED_LAYOUT';
export const HEADER_FIXED_LAYOUT = 'HEADER_FIXED_LAYOUT';
export const BOX_LAYOUT = 'BOX_LAYOUT';
export const NAV_DROPDOWN_ICON = 'NAV_DROPDOWN_ICON';
export const NAV_LIST_ICON = 'NAV_LIST_ICON';
export const NAV_ACTIVE_LIST_COLOR = 'NAV_ACTIVE_LIST_COLOR';
export const NAV_LIST_TITLE_COLOR = 'NAV_LIST_TITLE_COLOR';
export const NAV_LIST_TITLE_HIDE = 'NAV_LIST_TITLE_HIDE';
export const NAV_CONTENT_LEAVE = 'NAV_CONTENT_LEAVE';
export const NAV_COLLAPSE_LEAVE = 'NAV_COLLAPSE_LEAVE';
export const CONFIG_BLOCK = 'CONFIG_BLOCK';
export const CHANGE_PRE_LAYOUT = 'CHANGE_PRE_LAYOUT';

export const GET_LOGIN_URL = 'GET_LOGIN_URL';
export const GET_LOGIN_URL_RESULT = 'GET_LOGIN_URL_RESULT';
export const GET_LOGIN_URL_ERROR = 'GET_LOGIN_URL_ERROR';
export const SET_TOKEN = 'SET_TOKEN';
export const GET_BUNDLES = 'GET_BUNDLES';
export const GET_BUNDLES_RESULT = 'GET_BUNDLES_RESULT';
export const GET_BUNDLES_ERROR = 'GET_BUNDLES_ERROR';
export const GET_STREAM = 'GET_STREAM';
export const GET_STREAM_RESULT = 'GET_STREAM_RESULT';
export const GET_STREAM_ERROR = 'GET_STREAM_ERROR';
export const GET_PROVIDER_PARAMS = 'GET_PROVIDER_PARAMS';
export const GET_PROVIDER_PARAMS_RESULT = 'GET_PROVIDER_PARAMS_RESULT';
export const GET_PROVIDER_PARAMS_ERROR = 'GET_PROVIDER_PARAMS_ERROR';
export const GET_LOGO = 'GET_LOGO';
export const LOG_OUT = 'LOG_OUT';

export const SHOW_ALERT = 'SHOW_ALERT';
export const HIDE_ALERT = 'HIDE_ALERT';
export const SET_INSTITUTION = 'SET_INSTITUTION';
export const INCREASE_REDIRECT_LOGIN_COUNT = 'INCREASE_REDIRECT_LOGIN_COUNT';
export const RESET_REDIRECT_LOGIN_COUNT = 'RESET_REDIRECT_LOGIN_COUNT';

export const ACTION = 'ACTION';

export const getFunction = (token, var1) => ({
  type: ACTION,
  token: token,
  var1: var1,
});

export const getLoginUrl = (provider) => ({
  type: GET_LOGIN_URL,
  provider: provider,
});

export const setToken = (token) => ({
  type: SET_TOKEN,
  token: token
});

export const getBundles = (token) => ({
  type: GET_BUNDLES,
  token: token,
});

export const getStream = (token, stackName, applicationName) => ({
  type: GET_BUNDLES,
  token: token,
  stackName: stackName,
  applicationName: applicationName,
});

export const getProviderParams = (provider) => ({
  type: GET_PROVIDER_PARAMS,
  provider: provider
});

export const setInstitution = (institution) => ({
  type: SET_INSTITUTION,
  institution: institution
});

export const getLogo = () => ({
  type: GET_LOGO,
});

export const logout = () => ({
  type: LOG_OUT,
});
